export const OS_TYPE = {
  windows: /win/,
  macos: /mac/,
  linux: /linux/,
  android: /android/,
  ios: /iphone|ipad|ipod/,
}

export const HINTS = {
  newChat: {
    mac: '⌘+,',
    nonMac: 'ctrl+,',
  },
  history: {
    mac: '⌘+H',
    nonMac: 'ctrl+H',
  },
  chatSetting: {
    mac: '⌘+.',
    nonMac: 'ctrl+.',
  },
  ...(process.env.NODE_ENV === 'production'
    ? {
        generalAI: {
          mac: '⌘+1',
          nonMac: 'ctrl+1',
        },
        taxAI: {
          mac: '⌘+2',
          nonMac: 'ctrl+2',
        },
        docQuestion: {
          mac: '⌘+3',
          nonMac: 'ctrl+3',
        },
        drFinder: {
          mac: '⌘+4',
          nonMac: 'ctrl+4',
        },
        provCalc: {
          mac: '⌘+5',
          nonMac: 'ctrl+5',
        },
      }
    : {
        firstmate: {
          mac: '⌘+1',
          nonMac: 'ctrl+1',
        },
        docQuestion: {
          mac: '⌘+2',
          nonMac: 'ctrl+2',
        },
        drFinder: {
          mac: '⌘+3',
          nonMac: 'ctrl+3',
        },
        provCalc: {
          mac: '⌘+4',
          nonMac: 'ctrl+4',
        },
      }),
}
export const GENERIC_DOMAINS = new Set([
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'msn.com',
])
export const PWC_PILOT_ACCOUNT_ID = '75210392-6e7a-45d1-8b0a-cbfcee8dc67f'
