import { AuthContext, Button, ViewContext } from 'components/lib'
import { ReactComponent as Assistant } from './assistant.svg'
import { useContext, useEffect } from 'react'
import { WarningIcon } from 'icons'
import { PWC_PILOT_ACCOUNT_ID } from 'utils'

const SplashContent = ({ action }) => {
  return (
    <div className="flex gap-5">
      <div className="ml-[-1px]">
        <Assistant />
      </div>
      <div className="">
        <h2 className=" text-primary-dark font-semibold text-xl mb-3 flex items-center gap-1">
          <WarningIcon height={22} />
          Warning
        </h2>
        <p className="">Law Cyborg is a research tool only.</p>
        <p className="">
        <b>Do not enter PwC data, client names or data or personal information.</b>
        </p>
        <p className="">
        For more information on safe use see our <a className="underline" href="https://sites.google.com/pwc.com/pwc-nz-gen-ai-hub/home/responsible-use-of-genai%23h.e7g270g7p77k&sa=D&source=calendar&ust=1726279228496517&usg=AOvVaw0QPugbeceKvmZp11ImVI4R"> 
        PwC NZ Generative AI Hub - business rules</a>.
        </p>
        <br />
        <Button small text="I Agree" action={action} color={'brand3'} />
      </div>
    </div>
  )
}

const SplashPage = () => {
  const viewContext = useContext(ViewContext)
  const authContext = useContext(AuthContext)
  const userAccountId = authContext?.user?.account_id
  const shouldDisplaySplashPage = userAccountId && userAccountId === PWC_PILOT_ACCOUNT_ID

  useEffect(() => {
    const isAgreed = sessionStorage.getItem('isAgreed')
    if (!isAgreed && shouldDisplaySplashPage) {
      displaySplashPage()
    }
  }, [])

  const onAgree = () => {
    sessionStorage.setItem('isAgreed', 'true')
    viewContext.modal.hide()
  }

  const displaySplashPage = () => {
    viewContext.modal.show(
      {
        text: <SplashContent action={onAgree} />,
        closeOnOutsideClick: false,
        className:
          'max-w-5xl max-w-[42rem] absolute left-1/2 top-1/2 text-left transform -translate-x-1/2 -translate-y-1/2 min-w-[38rem] duration-300 transition-all ease-in-out [&>section]:p-8 [&>section]:pl-0 [&>section]:pb-0',
      },
      () => {}
    )
  }
  return null
}

export default SplashPage
