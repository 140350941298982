const WarningIcon = ({ width = 30, height = 29, fill = '#12367F', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 29" width={width} height={height} fill="none" {...props}>
    <g fill={fill} clipPath="url(#a)">
      <path d="M15.3 19.874c-.81 0-1.49.68-1.49 1.49 0 .811.68 1.491 1.49 1.491.78 0 1.49-.68 1.454-1.455a1.455 1.455 0 0 0-1.454-1.526Z" />
      <path d="M29.094 25.156a5.089 5.089 0 0 0 .012-5.15L19.77 3.837a5.072 5.072 0 0 0-4.465-2.593 5.09 5.09 0 0 0-4.465 2.587L1.493 20.017a5.13 5.13 0 0 0 .018 5.18 5.102 5.102 0 0 0 4.453 2.558H24.61c1.866 0 3.541-.971 4.483-2.599Zm-2.027-1.168a2.817 2.817 0 0 1-2.462 1.424H5.958a2.779 2.779 0 0 1-2.432-1.395 2.82 2.82 0 0 1-.006-2.843l9.347-16.18a2.766 2.766 0 0 1 2.438-1.412c1.02 0 1.932.53 2.439 1.419l9.341 16.179c.5.87.495 1.92-.018 2.808Z" />
      <path d="M14.93 9.412c-.71.203-1.15.846-1.15 1.627.035.471.065.948.1 1.42l.305 5.347c.035.608.506 1.049 1.114 1.049.609 0 1.085-.471 1.115-1.085 0-.37 0-.71.036-1.085.066-1.15.137-2.301.203-3.452.036-.745.101-1.49.137-2.235 0-.269-.036-.507-.137-.745a1.494 1.494 0 0 0-1.723-.841Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.8 0h29v29H.8z" />
      </clipPath>
    </defs>
  </svg>
)

export { WarningIcon }
