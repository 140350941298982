/***
 *
 *   ONBOARDING
 *   Example of onboarding flow*
 *
 **********/

import React, { Fragment, useContext } from 'react'
import { AuthContext, Onboarding, Form, Message, useAPI } from 'components/lib'
import { GENERIC_DOMAINS } from 'utils'

export function OnboardingView(props) {
  const context = useContext(AuthContext)
  const { data } = useAPI('/api/user/email')

  const views = [
    {
      name: 'Getting Started',
      description: `Welcome, ${context.user.name}!`,
      component: <Welcome />,
    },
  ]

  if (context.permission.admin) {
    views.push(
      {
        name: 'Your Account',
        description: 'Tell us about your account',
        component: <SetAccountDetails email={data?.email} />,
      },
      {
        name: 'Invite your team',
        description: "Don't hog the app to yourself!",
        component: <InviteUsers />,
      }
    )
  }

  if (context.user.duplicate_user) {
    views.unshift({
      name: 'Important!',
      description: '',
      component: <DuplicateUser />,
    })
  }

  return <Onboarding save onFinish="/" views={views} />
}

function DuplicateUser() {
  return (
    <Message
      type="warning"
      title={`You already have an account`}
      text="We noticed you have already registered an account, so we used your original password to save you any confusion in the future."
    />
  )
}

function Welcome() {
  return (
    <Fragment>
      <p>Hi! Thanks for signing up to Law Cyborg.</p>
      <p>
        As a small, early-stage startup, we really appreciate your support. If you encounter any issues please get in
        touch and we'll work to resolve it as soon as we can.
      </p>
      <p>
        <strong>Good luck! 🚀 </strong>
      </p>
    </Fragment>
  )
}

function InviteUsers(props) {
  return (
    <Form
      inputs={{
        email: {
          label: 'Email',
          type: 'email',
          required: true,
        },
      }}
      buttonText="Send Invite"
      url="/api/invite"
      method="POST"
    />
  )
}

function SetAccountDetails({ email = '' }) {
  const getAccountName = (email) => {
    const defaultAccountName = 'My Account'
    if (typeof email !== 'string' || !email.includes('@')) return defaultAccountName

    const [, domain] = email.split('@')
    if (!domain) return defaultAccountName

    const [accountName] = domain.split('.')
    return GENERIC_DOMAINS.has(domain.toLowerCase()) ? defaultAccountName : accountName
  }

  return (
    <Form
      buttonText="Save"
      url="/api/user"
      method="PATCH"
      inputs={{
        account_name: {
          type: 'text',
          label: 'Organisation',
          required: true,
          value: getAccountName(email),
        },
      }}
      callback={() => {}}
    />
  )
}
