import SplashPage from 'components/ai/splashPage'
import { AuthContext, ViewContext } from 'components/lib'
import React, { useState, useCallback } from 'react'
import { useContext } from 'react'
const Settings = require('settings.json')

export const WebSocketContext = React.createContext({
  send: () => {},
  close: () => {},
})

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null)
  const viewContext = useContext(ViewContext)
  const authContext = useContext(AuthContext)
  // Default WebSocket event listeners
  const defaultOnClose = useCallback(() => {
    console.log('Socket closed')
    setSocket(null)
  }, [])

  const defaultOnError = useCallback((error) => {
    console.log('Socket error', error)
  }, [])

  const initializeSocket = useCallback(() => {
    const jwt = JSON.parse(localStorage.getItem('user')).token
    const webSocketUrl = `${Settings[process.env.NODE_ENV].websocket_url}?token=${jwt}`

    let socketIo = null
    try {
      socketIo = new WebSocket(webSocketUrl)
    } catch (error) {
      viewContext.notification.show('User unauthenticated. Logging out...', 'error')
      setTimeout(authContext.signout, 2000)
      console.log('Error creating socket', error)
      return null
    }

    setSocket(socketIo)
    socketIo.onclose = defaultOnClose
    socketIo.onerror = defaultOnError

    return socketIo
  }, [defaultOnClose, defaultOnError, viewContext.notification, authContext.signout])

  const send = useCallback(
    (message, onMessage, onError, onClose) => {
      if (socket) {
        socket.onclose = defaultOnClose
        socket.close()
      }
      if (!onMessage) {
        throw new Error('onMessage is required')
      }
      const newSocket = initializeSocket()
      if (!newSocket) {
        return
      }
      newSocket.onopen = () => {
        newSocket.send(JSON.stringify(message))
      }
      newSocket.onmessage = onMessage
      newSocket.onerror = onError ? onError : defaultOnError
      newSocket.onclose = onClose ? onClose : defaultOnClose
    },
    [socket, initializeSocket, defaultOnClose, defaultOnError]
  )

  const close = useCallback(() => {
    if (socket) {
      socket.close()
      setSocket(null)
    }
  }, [socket])

  return <WebSocketContext.Provider value={{ send, close, socket }}>{children}</WebSocketContext.Provider>
}

export const withWebSocket = (WrappedComponent) => {
  return function WrappedWithWebSocket(props) {
    return (
      <WebSocketProvider>
        <SplashPage />
        <WrappedComponent {...props} />
      </WebSocketProvider>
    )
  }
}
