import React from 'react'

import {
  FileInput,
  useConversation,
  ConversationDisplay,
  RefreshButton,
  ChatInputForm,
  ViewContext,
  ChatDisclaimer,
  useConversationAutoScroll,
} from 'components/lib'
import { useRef } from 'react'
import useCallAPI from 'components/hooks/callApi'
import { useEffect } from 'react'
import Animatedloader from 'components/loader/animatedloader'
import { useContext } from 'react'
import { docUpload } from './definitions.js'
import { useState } from 'react'
import ChatHistory from 'components/ai/chatHistory.js'

export function DocUpload() {
  const chatLogRef = useRef()
  const [autoScroll, setAutoScroll] = useState(true)
  const {
    exchanges,
    inputMessage,
    setInputMessage,
    handleSubmit,
    loading: chatLoading,
    canSend,
    setFileID,
    conversations,
    conversationId,
    setConversationName,
    conversationName,
    branch,
    swapBranch,
    stopStreaming,
    loadConversation,
    doCreateConversation,
    loadConversations,
    historyCached,
  } = useConversation(docUpload)
  useConversationAutoScroll(chatLogRef, autoScroll, setAutoScroll, exchanges)
  const [callAPI, data, loading, error] = useCallAPI()
  const context = useContext(ViewContext)

  useEffect(() => {
    if (data) {
      setFileID(data.fileID)
      doCreateConversation(conversationName.slice(0, 40), docUpload.chatType, data.fileID)
    }
  }, [data, conversationName, doCreateConversation, setFileID])

  useEffect(() => {
    if (error) {
      context.notification.show('Could not upload file', 'error', false)
    }
  }, [error])

  return data || conversationId ? (
    <div className="flex flex-col p-[1.5rem] h-full w-full no-scrollbar relative">
      <ChatHistory
        currentConversationId={conversationId}
        loadConversation={loadConversation}
        conversations={conversations}
        className={'top-0'}
        bgHexColor={'#f3f8fa'}
        setFileID={setFileID}
        onOpen={loadConversations}
        enableRefresh={true}
        loading={!historyCached}
      />
      <div ref={chatLogRef} className="flex1 self-center overflow-y-auto w-[100%] no-scrollbar" id="chat">
        <ConversationDisplay
          exchanges={exchanges}
          loading={chatLoading}
          branch={branch}
          swapBranch={swapBranch}
          canSend={canSend}
        />
      </div>
      <div className="lg:w-[67%] md:w-[90%] self-center relative webfill flex items-center justify-center ">
        {/* <RefreshButton enabled={true} /> */}
        <ChatInputForm
          stopStreaming={stopStreaming}
          inputMessage={inputMessage}
          autoFocus
          setInputMessage={setInputMessage}
          handleSubmit={handleSubmit}
          enableSend={canSend}
          placeholderText={'Ask a question...'}
        />
      </div>
    </div>
  ) : loading ? (
    <div className="flex justify-center items-center h-full">
      <Animatedloader />
    </div>
  ) : (
    <>
      <div className="flex flex-col p-[1.5rem] h-full w-full no-scrollbar relative ">
        <div className="flex1 self-center overflow-y-auto w-[100%] no-scrollbar">
          <FileInput
            name="File Uploader"
            // LV Accepted file types are dictated by file the assistants API supported files
            // for retrieval: https://platform.openai.com/docs/assistants/tools/file-search/supported-files
            accept={[
              'pdf',
              'vnd.openxmlformats-officedocument.wordprocessingml.document', //docx
              'plain', //txt
              'json',
            ]}
            className="mt-14 lg:w-[60%] md:w-[80%] mx-auto"
            max={1}
            onChange={async (name, files) => {
              if (files && files.length > 0) {
                const formData = new FormData()
                formData.append('file', files[0].data)
                setConversationName(files[0].name)
                callAPI({
                  url: '/api/v1/ai/upload',
                  method: 'POST',
                  requestData: formData,
                  headers: { 'Content-Type': 'multipart/form-data' },
                })
              }
            }}
          />
          <ChatHistory
            currentConversationId={conversationId}
            className={'top-0'}
            loadConversation={loadConversation}
            conversations={conversations}
            bgHexColor={'#f3f8fa'}
            setFileID={setFileID}
            onOpen={loadConversations}
            loading={!historyCached}
            enableRefresh={false}
          />
          <ChatDisclaimer disclaimers={docUpload.disclaimers} />
        </div>
      </div>
    </>
  )
}
